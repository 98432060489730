<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card title="Information" class="mb-5">
        <template slot="actions">
          <vs-button size="small" :to="{name:'shipments-edit', params:{id: $route.params.id}}" type="line" icon-pack="feather" icon="icon-edit"></vs-button>
        </template>
          <div class="vx-row">
              <div class="vx-col">
                  
                  <table class="info sm:w-full md:w-1/2">
                    <tr>
                      <td class="font-semibold">Shipment No.</td>
                      <td>{{dt.code}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">Shipment Plan</td>
                      <td>{{dt.job_assign_time | moment("dddd, Do MMMM YYYY")}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">Type</td>
                      <td>{{dt.type}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">Estimate Distance</td>
                      <td>{{dt.distance}} Km</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">Estimate Time</td>
                      <td>{{dt.time / 60}} Minutes</td>
                    </tr>
                  </table>
              </div>
          </div>
          <vs-divider></vs-divider>
          <div class="vx-row">
              <div class="vx-col sm:w-full md:w-1/2">
                <table class="info sm:w-full md:w-1/2">
                  <tr>
                    <td class="font-semibold">Police No.</td>
                    <td>{{dt.police_no}}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Driver Name</td>
                    <td>{{dt.driver_name}}</td>
                  </tr>
                  <tr v-for='(t,i) in dt.timeline' :key="i">
                    <td class="font-semibold">{{t.name}}</td>
                    <td>{{t.log_time}}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Total Times</td>
                    <td>{{dt.duration}}</td>
                  </tr>
                </table>
              </div>
              <div class="vx-col sm:w-full md:w-1/2">
                <l-map ref="myMap" style="height:300px;" :center.sync="initLatLng" :zoom="14" @ready="mapReady()">
                  <l-tile-layer :options="MUTileLayerOptions" :url="MUTileLayerUrl"></l-tile-layer>
                  <l-marker v-for="(d,i) in fromMarkers" :key="`a${i}`" :icon="iconMarker" :lat-lng="{lat:d.latitude,lng:d.longitude}">
                    <l-tooltip :options="MULTooltipOptions">
                      <div>
                        <p class="font-bold">Pickup</p>
                        <p class="font-semibold">{{d.name}}</p>
                        <p>{{d.address}}</p>
                      </div>
                    </l-tooltip>
                  </l-marker>
                  
                  <l-marker v-for="(d,i) in toMarkers" :key="`b${i}`" :icon="iconMarker" :lat-lng="{lat:d.latitude,lng:d.longitude}">
                    <l-tooltip :options="MULTooltipOptions">
                      <div>
                        <p class="font-bold">Destination</p>
                        <p class="font-semibold">{{d.name}}</p>
                        <p>{{d.address}}</p>
                      </div>
                    </l-tooltip>
                  </l-marker>
                </l-map>
              </div>
          </div>
          <span v-if='!hide_manifest'>
              <div class="vx-row">
                  <div class="vx-col w-full">
                      
                      <vs-divider></vs-divider>
                      <manifest :delivery_order_id='$route.params.id' />
                  </div>
              </div>
          </span>
          <vs-divider></vs-divider>
          <vs-table :sst="true" search @search="handleSearch" :data="files.data" @sort="handleSort" class="mb-3" >
            <template slot="header">
              <vs-button
                color="success"
                class="mr-1 ml-1"
                :to="{ name: 'shipments-upload' }"
                >Upload File</vs-button
              >
            </template>
            <template slot="thead">
              <vs-th sort-key="file_name">Preview</vs-th>
              <vs-th sort-key="file_name">Filename</vs-th>
              <vs-th sort-key="created_at">Upload Date</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.file_path"><img :src="tr.file_path" style="width: 300px;" /></vs-td>
                <vs-td label="Filename" :data="tr.file_name"><a :href="tr.file_path" target="_blank">{{tr.file_name}}</a></vs-td>
                <vs-td label="Upload Date" :data="tr.created_at">{{tr.created_at}}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination :total="files.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Manifest from '@pages/operational/manifests/Index.vue'
import maputility from '@/mixins/maputility'
import L from 'leaflet'
require('leaflet-routing-machine');

export default {
  props : ['delivery_order_id', 'hide_manifest'],
  components : {
    Manifest
  },
  mixins:[maputility],
  data(){
    return {
      dt: {},
      details: [],
      cost_lists: [],
      idDeleteCost: null,
      idApproveCost: null,
      initLatLng: {lat:0,lng:0},
      fromMarkers:[],
      toMarkers:[],
      map:null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null
    }
  },
  computed:{
    ...mapState({
      files: state => state.delivery_orders.rows
    })
  },
  watch:{
    search(){
      this.currentPage=1
      this.getDataFileDo()
    },
    currentPage(){
      this.getDataFileDo()
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: 'delivery_orders/show',
      dispatchFileDo: 'delivery_orders/show_file_do'
    }),
    mapReady(){
      this.map = this.$refs.myMap.mapObject
    },
    async getData(){
      let { data } = await this.dispatchShow(this.$route.params.id)
      this.dt = data
      this.fromMarkers = []
      this.toMarkers = []
      for (let i = 0; i < data.from.length; i++) {
        const e = data.from[i];
        if(!e.latitude||!e.longitude) continue;
        this.initLatLng = {lat:e.latitude,lng:e.longitude}
        this.fromMarkers.push(e)
      }
      for (let i = 0; i < data.to.length; i++) {
        const e = data.to[i];
        if(!e.latitude||!e.longitude) continue;
        this.toMarkers.push(e)
      }
      /* ------------ map route ------------- */
      for (let i = 0; i < this.fromMarkers.length; i++) {
        const from = this.fromMarkers[i];
        for (let x = 0; x < this.toMarkers.length; x++) {
          const to = this.toMarkers[x];
          L.Routing.control({
            waypoints:[
              L.latLng(from.latitude,from.longitude),
              L.latLng(to.latitude,to.longitude)
            ],
            createMarker: () => "",
            routeWhileDragging: false,
            show: false,
            addWaypoints: false,
            styles: {color:'#0085f2',opacity:0.8, weight:5}
          }).addTo(this.map)
        }
      }
      /* ------------------------------------ */
    },
    async confirmApproveCost(){
      try {
        await this.$store.dispatch('shipments/approve_costs', this.idApproveCost)
        this.getCosts()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been approved successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deleteCost(id){
      this.idDeleteCost = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDeleteCost
      })
    },
    async confirmDeleteCost(){
      try {
        await this.$store.dispatch('shipments/delete_costs', this.idDeleteCost)
        this.getCosts()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    async getDataFileDo(){
      let payload = {
        delivery_order_id: this.$route.params.id,
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      await this.dispatchFileDo(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getDataFileDo()
    }
  },
  async mounted(){
    if(this.delivery_order_id) {
        this.$route.params.id = this.delivery_order_id
    }
    await this.getData()
    await this.getDataFileDo();
  }
}
</script>

<style lang="scss">
  .leaflet-control-container .leaflet-routing-container-hide {
      display: none;
  }
</style>