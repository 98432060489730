import L from 'leaflet'
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import {LMap, LTileLayer, LMarker, LTooltip} from 'vue2-leaflet';

export default {
  data() {
    return {
      MUTileLayerOptions: { id: 'mapbox/streets-v11' },
      MUTileLayerUrl: "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmFqYXJmdWFkIiwiYSI6ImNraW9jdzBjMTFiZ28zMG13bW55aXYwbncifQ.kmgRy9O_gZ2RUlbjtWxb-Q",
      MULTooltipOptions: {direction: `top`},
      iconMarker: L.icon({
        iconUrl: require('@/assets/images/ico/marker-red.svg'),
        iconSize: [40,40]
      }),
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  }
}